import React from 'react';
import { Accordion } from 'react-bootstrap'; // Import Accordion from React-Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

const FAQSection = () => {
  return (
    <section id="FAQ" className="faq py-5">
      <h2 className="text-center pageTitle mb-3">Frequently Asked Questions</h2>
      <Accordion flush>
        <Accordion.Item eventKey="0" className="mb-2">
          <Accordion.Header>What is WaveSwaps?</Accordion.Header>
          <Accordion.Body>
            <p className='justify'>
              WaveSwaps is a decentralized platform designed to address the significant challenge of token liquidity within the cryptocurrency ecosystem. It introduces a revolutionary approach by allowing users to recycle their illiquid tokens—tokens that are otherwise difficult to trade or convert—into more liquid forms.<br /><br />This recycling process is powered by two core mechanisms: GBL swapping bots and Liquidity Nodes. Through these features, WaveSwaps not only helps users unlock the value of their stagnant assets but also reinforces the overall sustainability and liquidity of the cryptocurrency market.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1" className="mb-2">
          <Accordion.Header>How does WaveSwaps help with liquidity?</Accordion.Header>
          <Accordion.Body>
            <p className='justify'>
              WaveSwaps helps solve liquidity issues by providing a method for users to permanently remove illiquid tokens from circulation, replacing them with liquid assets like GBL tokens or WaveCoin. Through the use of GBL swapping bots and Liquidity Nodes, WaveSwaps ensures that tokens which would otherwise remain unused or difficult to trade are converted into liquid assets. This reduction in circulating supply increases the value of tokens, improves market fluidity, and ensures that the cryptocurrency ecosystem can operate more efficiently.<br /><br />By aligning with the natural process of recycling, WaveSwaps helps keep the token economy balanced and sustainable.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2" className="mb-2">
          <Accordion.Header>What are GBL swapping bots?</Accordion.Header>
          <Accordion.Body>
            <p className='justify'>
              GBL swapping bots are automated mechanisms that allow users to recycle their illiquid tokens into liquid forms using GBL tokens as a liquidity provision asset. These bots work by taking the user’s illiquid tokens and swapping them for GBL tokens over a specific time period, based on the user’s activity level and the market conditions. The swapped tokens are permanently removed from circulation, and users receive an increased amount of GBL tokens as a result.<br /><br />This process not only enhances the liquidity of GBL but also positively impacts the entire ecosystem by reducing the number of illiquid tokens in the market.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3" className="mb-2">
          <Accordion.Header>What is the role of Liquidity Nodes in WaveSwaps?</Accordion.Header>
          <Accordion.Body>
            <p className='justify'>
              Liquidity Nodes play a crucial role in the WaveSwaps ecosystem. These are non-fungible tokens (NFTs) that function as machines designed to recycle illiquid tokens into liquid assets, specifically WaveCoin. To activate a Liquidity Node, users must provide Power Cores, which fuel the node’s operation. Once activated, the Liquidity Node starts generating daily rewards in the form of WaveCoin, effectively recycling the user’s illiquid tokens and reducing the circulating supply.<br /><br />Liquidity Nodes vary in price, level, and lifespan, meaning higher-level nodes can provide even greater daily rewards and benefits to their holders.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4" className="mb-2">
          <Accordion.Header>What are Power Cores?</Accordion.Header>
          <Accordion.Body>
            <p className='justify'>
              Power Cores are limited-supply NFTs that act as the energy source for Liquidity Nodes. Without Power Cores, a Liquidity Node cannot be activated or maintained. By adding Power Cores to a Liquidity Node, users can start the recycling process of their illiquid tokens and begin earning daily WaveCoin rewards. Power Cores represent the raw material that fuels the "machine" analogy of Liquidity Nodes, where illiquid tokens are recycled into more valuable, liquid assets.<br /><br />The supply of Power Cores is limited, making them an essential resource within the WaveSwaps ecosystem.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5" className="mb-2">
          <Accordion.Header>How is WaveSwaps decentralized?</Accordion.Header>
          <Accordion.Body>
            <p className='justify'>
              WaveSwaps embraces decentralization through its **DAO-driven governance model**. A Decentralized Autonomous Organization (DAO) gives users direct control over the platform by allowing them to vote on key decisions using WSHARE tokens. This ensures that the direction of the platform is not determined by a central authority but by the collective decision of the community.<br /><br />Users with WSHARE tokens can vote on proposals related to platform development, token management, and other critical issues, ensuring that the WaveSwaps ecosystem remains transparent and community-driven.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6" className="mb-2">
          <Accordion.Header>What is the role of WaveCoin in the platform?</Accordion.Header>
          <Accordion.Body>
            <p className='justify'>
              WaveCoin is the native token of the WaveSwaps ecosystem, playing a central role in the platform’s liquidity recycling mechanisms. It is primarily generated through Liquidity Nodes, where users earn daily rewards in WaveCoin by recycling illiquid tokens. In addition to being a reward, WaveCoin is used for various transactions within the platform, including purchasing assets, staking, and governance.<br /><br />As the native currency, WaveCoin ensures that the platform operates smoothly, offering users liquidity and value for their participation.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="7" className="mb-2">
          <Accordion.Header>How does the GBL token impact liquidity?</Accordion.Header>
          <Accordion.Body>
            <p className='justify'>
              The GBL token is integral to WaveSwaps' liquidity provision system. It is used within the GBL swapping bots, where illiquid tokens are recycled into GBL, thereby reducing the overall token supply in circulation. As more users engage with the swapping bots, the demand for GBL tokens increases, enhancing their liquidity. By removing illiquid tokens from circulation and introducing liquid GBL tokens, the platform stabilizes the market and ensures that users have access to a valuable and tradable asset.<br /><br />This continuous cycle strengthens both the liquidity and value of GBL and other tokens in the ecosystem.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="8" className="mb-2">
          <Accordion.Header>Can new users participate without prior token balances?</Accordion.Header>
          <Accordion.Body>
            <p className='justify'>
              Yes, new users can easily participate in the WaveSwaps ecosystem without needing to hold prior balances from other platforms. New users can purchase Liquidity Nodes or Power Cores using mainstream cryptocurrencies and start the recycling process to earn WaveCoin or GBL tokens. This flexibility ensures that anyone can engage with the platform, whether they are migrating from the Geton Ecosystem or joining WaveSwaps for the first time. This inclusive approach helps grow the community and increases overall liquidity on the platform.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="9" className="mb-2">
          <Accordion.Header>What makes WaveSwaps sustainable?</Accordion.Header>
          <Accordion.Body>
            <p className='justify'>
              WaveSwaps promotes sustainability by utilizing a recycling metaphor to handle illiquid tokens. Instead of allowing these tokens to remain inactive or oversupplied in the market, WaveSwaps recycles them into liquid forms such as WaveCoin or GBL tokens. This reduction in circulating supply prevents market saturation, creating scarcity, and thereby driving up token value.<br /><br />Moreover, the platform aligns with the global trend toward sustainability, ensuring that resources are managed efficiently and value is consistently regenerated from previously stagnant assets. This recycling process makes WaveSwaps a sustainable and forward-thinking solution for DeFi.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

    </section>
  );
}

export default FAQSection;
