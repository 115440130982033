import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

const roadmapItems = [
  { 
    quarter: "Q2, 2023", 
    title: "WaveSwaps DApp Launch", 
    description: [
      "First version of DApp launched.",
      "Implemented initial GBL swap mechanism."
    ], 
    status: "completed", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/"
  },
  { 
    quarter: "Q3, 2023", 
    title: "GBL Rewards and Loans", 
    description: [
      "Introduced GBL Loans and rewards.",
      "Distributed half of GBL supply."
    ], 
    status: "completed", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/"
  },
  { 
    quarter: "Q4, 2023", 
    title: "Multichain Expansion Begins", 
    description: [
      "Expanded to Polygon, Arbitrum, Optimism.",
      "GBL tokens distributed globally."
    ], 
    status: "completed", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/"
  },
  { 
    quarter: "Q1, 2024", 
    title: "Recycle Pool and DAO Governance", 
    description: [
      "DAO-managed Recycle Pool established.",
      "Community voting on recycling assets."
    ], 
    status: "completed", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/"
  },
  { 
    quarter: "Q2, 2024", 
    title: "GBL Bridge Launch", 
    description: [
      "Launched GBL Bridge for multi-chain.",
      "Maintains GBL price across networks."
    ], 
    status: "completed", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/"
  },
  { 
    quarter: "Q3, 2024", 
    title: "Liquidity Nodes and Power Cores", 
    description: [
      "Introduced Liquidity Nodes and Power Cores.",
      "Enhanced token recycling mechanism."
    ], 
    status: "in-progress", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/"
  },
  { 
    quarter: "Q3, 2024", 
    title: "Upgraded GBL Swapping Bots", 
    description: [
      "Improved GBL bots with Nodes.",
      "Boosted user rewards and liquidity."
    ], 
    status: "in-progress", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/"
  },
  { 
    quarter: "Q4, 2024", 
    title: "Open-Source Transformation Begins", 
    description: [
      "Transitioned platform to open-source.",
      "Invited community to contribute code."
    ], 
    status: "planned", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/"
  },
  { 
    quarter: "Q1, 2025", 
    title: "Community-Driven Asset Listing", 
    description: [
      "Community proposes new recycling assets.",
      "DAO votes to list new tokens."
    ], 
    status: "planned", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/"
  },
  { 
    quarter: "Q2, 2025", 
    title: "DAO-Led Platform Management", 
    description: [
      "Complete DAO control over governance.",
      "Community manages platform decisions."
    ], 
    status: "planned", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/"
  },
];


function RoadmapSection() {
  const timelineRef = useRef(null);

  useEffect(() => {
    const inProgressItem = document.querySelector('.items-list.in-progress');
    if (inProgressItem && timelineRef.current) {
      const offsetLeft = inProgressItem.offsetLeft - (timelineRef.current.offsetWidth / 2) + (inProgressItem.offsetWidth / 2);
      timelineRef.current.scrollLeft = offsetLeft;
    }
  }, []);

  return (
    <section className="roadmap py-5">
      <h2 className="text-center roadmap-header">Roadmap</h2>
      <div className="horizontal-timeline" ref={timelineRef}>
        <ul className="list-inline items">
          {roadmapItems.map((item, index) => (
            <li key={index} className={`list-inline-item items-list ${item.status}`}>
              <div className="px-4">
                <div className={`event-date badge ${getBadgeClass(item.status)}`}>{item.quarter}</div>
                <h5 className="pt-2">{item.title}</h5>
                {item.description.map((line, lineIndex) => (
                  <p key={lineIndex} className="text-muted mb-1">{line}</p>
                ))}
                <div className={`read-more-container ${!item.showReadMore ? 'empty-button' : ''}`}>
                  {item.showReadMore && (
                    <a href={item.readMoreLink} className="btn btn-primary mt-2 btn-sm">Read more</a>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

// Helper function to determine badge class based on status
const getBadgeClass = (status) => {
  switch (status) {
    case 'completed':
      return 'bg-success'; // Green badge for completed
    case 'in-progress':
      return 'bg-warning'; // Yellow badge for in-progress
    case 'planned':
      return 'bg-info'; // Blue badge for planned
    default:
      return '';
  }
};

export default RoadmapSection;
