import React, { useRef, useEffect } from 'react';

const MatrixEffect = ({ className = '', quantity = 50, speed = 1 }) => {
  const canvasRef = useRef(null);
  const canvasContainerRef = useRef(null);
  const context = useRef(null);
  const columns = useRef([]);
  const canvasSize = useRef({ w: 0, h: 0 });
  const dpr = typeof window !== 'undefined' ? window.devicePixelRatio : 1;

  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // Characters to use in the effect
  const fontSize = 16; // Font size for the falling characters
  const columnsCount = useRef(0); // Number of columns of characters
  
  useEffect(() => {
    if (canvasRef.current) {
      context.current = canvasRef.current.getContext('2d');
    }
    initCanvas();
    animate();
    window.addEventListener('resize', initCanvas);

    return () => {
      window.removeEventListener('resize', initCanvas);
    };
  }, []);

  const initCanvas = () => {
    resizeCanvas();
    columnsCount.current = Math.floor(canvasSize.current.w / fontSize);
    columns.current = Array(columnsCount.current).fill(0); // Initialize columns with starting y-position 0
  };

  const resizeCanvas = () => {
    if (canvasContainerRef.current && canvasRef.current && context.current) {
      canvasSize.current.w = canvasContainerRef.current.offsetWidth;
      canvasSize.current.h = canvasContainerRef.current.offsetHeight;
      canvasRef.current.width = canvasSize.current.w * dpr;
      canvasRef.current.height = canvasSize.current.h * dpr;
      canvasRef.current.style.width = canvasSize.current.w + 'px';
      canvasRef.current.style.height = canvasSize.current.h + 'px';
      context.current.scale(dpr, dpr);
      context.current.font = `${fontSize}px monospace`; // Set font style for characters
    }
  };

  const drawMatrix = () => {
    if (context.current) {
      context.current.clearRect(0, 0, canvasSize.current.w, canvasSize.current.h); // Clear canvas for next frame

      context.current.fillStyle = '#0b41821a'; // Matrix green color
      columns.current.forEach((y, index) => {
        const text = characters.charAt(Math.floor(Math.random() * characters.length)); // Pick a random character
        const x = index * fontSize; // Calculate x position based on column index

        // Draw character
        context.current.fillText(text, x, y * fontSize);

        // Reset the y position if it goes off-screen
        if (y * fontSize > canvasSize.current.h && Math.random() > 0.975) {
          columns.current[index] = 0;
        } else {
          columns.current[index] = y + speed; // Move the character down by speed multiplier
        }
      });
    }
  };

  const animate = () => {
    drawMatrix();
    window.requestAnimationFrame(animate);
  };

  return (
    <div className={className} ref={canvasContainerRef} aria-hidden="true">
      <canvas ref={canvasRef} />
    </div>
  );
};

export default MatrixEffect;
