import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'; 
import { FaYoutube, FaGithub, FaTelegramPlane } from 'react-icons/fa'; // Importing icons
import { BsMedium, BsTwitterX } from 'react-icons/bs'; // Importing Medium icon from react-icons/bs

const logo = '/assets/images/logo.svg';

function Footer() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Check screen width to activate dropdown toggle only on small screens
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    // Set the initial value
    checkScreenWidth();

    // Add a resize event listener to adjust the state when window size changes
    window.addEventListener('resize', checkScreenWidth);

    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  const handleToggle = (e) => {
    if (isSmallScreen) {
      const menu = e.currentTarget.nextElementSibling;
      if (menu.classList.contains('show')) {
        menu.classList.remove('show');
      } else {
        menu.classList.add('show');
      }
    }
  };

  return (
    <footer className="footer">
      <div className="footer-container"> {/* Container for alignment */}
        <Row className="footer-columns align-items-start">
          <Col xs={12} md={3} lg={4} className="footer-logo">
            <img src={logo} alt="WaveSwaps" className="logo" />
          </Col>

          {/* Group "Staking" and "Liquidity" in one row on small screens */}
          <Col xs={12} sm={2} md={2} lg={2} className="column-h3">
            <h3 className={`h3-footer ${isSmallScreen ? 'dropdown-toggle' : ''}`} onClick={handleToggle}>
              Staking
            </h3>
            <ul className={`footer-menu ${isSmallScreen ? '' : 'show'}`}>
              <li className="footer-li">
                <a href="https://nodes.waveswaps.com/staking" rel="noopener noreferrer">
                  Stake WAVE
                </a>
              </li>
              <li className="footer-li">
                <a href="https://nodes.waveswaps.com/staking-gbl" rel="noopener noreferrer">
                  Stake GBL
                </a>
              </li>
            </ul>
          </Col>

          <Col xs={12} sm={2} md={2} lg={2} className="column-h3">
            <h3 className={`h3-footer ${isSmallScreen ? 'dropdown-toggle' : ''}`} onClick={handleToggle}>
              Liquidity
            </h3>
            <ul className={`footer-menu ${isSmallScreen ? '' : 'show'}`}>
              <li className="footer-li">
                <a href="https://nodes.waveswaps.com/" rel="noopener noreferrer">
                  WAVE Nodes
                </a>
              </li>
              <li className="footer-li">
                <a href="https://swaps.waveswaps.com/" rel="noopener noreferrer">
                  GBL Swaps
                </a>
              </li>
            </ul>
          </Col>

          {/* Group "Integrations" and "Support" in another row on small screens */}
          <Col xs={12} sm={2} md={2} lg={2} className="column-h3">
            <h3 className={`h3-footer ${isSmallScreen ? 'dropdown-toggle' : ''}`} onClick={handleToggle}>
              Integrations
            </h3>
            <ul className={`footer-menu ${isSmallScreen ? '' : 'show'}`}>
              <li className="footer-li">
                <a href="https://geton.global" rel="noopener noreferrer">
                  GetonGlobal
                </a>
              </li>
            </ul>
          </Col>

          <Col xs={12} sm={2} md={2} lg={2} className="column-h3">
            <h3 className={`h3-footer ${isSmallScreen ? 'dropdown-toggle' : ''}`} onClick={handleToggle}>
              Support
            </h3>
            <ul className={`footer-menu ${isSmallScreen ? '' : 'show'}`}>
              <li className="footer-li">
                <a href="https://docs.waveswaps.com/" rel="noopener noreferrer">
                  Docs
                </a>
              </li>
              <li className="footer-li">
                <Link to="/supplies">Supplies</Link>
              </li>
              <li className="footer-li">
                <a href="https://docs.waveswaps.com/contact/customer-support" rel="noopener noreferrer">
                  Contact
                </a>
              </li>
              <li className="footer-li">
                <a href="#FAQ" rel="noopener noreferrer" className="no-underline">
                  FAQs
                </a>
              </li>
            </ul>
          </Col>
        </Row>

        <div className="footer-social">
          <ul>
            <li>
              <a href="https://t.me/waveswaps" target="_blank" rel="noopener noreferrer">
                <FaTelegramPlane />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/waweswaps" target="_blank" rel="noopener noreferrer">
                <BsTwitterX />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@WaveSwaps" target="_blank" rel="noopener noreferrer">
                <FaYoutube />
              </a>
            </li>
            <li>
              <a href="https://github.com/waveswaps" target="_blank" rel="noopener noreferrer">
                <FaGithub />
              </a>
            </li>
            <li>
              <a href="https://medium.com/@waveswaps" target="_blank" rel="noopener noreferrer">
                <BsMedium />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
