import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'; 

const logo = '/assets/images/logo.svg'; 

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false); 
  const [activeDropdown, setActiveDropdown] = useState(null); // Track which dropdown is active

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); 
  };

  const handleMouseEnter = (menuName) => {
    setActiveDropdown(menuName); // Open dropdown on hover
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null); // Close dropdown when the mouse leaves
  };

  return (
    <header className="header">
      <div className="container">
        <div className="logo mb-2 mt-1">
          <a href="https://waveswaps.com" rel="noopener noreferrer">
            <img src={logo} alt="WaveSwaps" className="logo" />
          </a>
        </div>

        <div className="hamburger" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>

        <nav className={`navigation ${isMenuOpen ? 'open' : ''}`}>
          <ul className="mb-1">
            <li><Link to="/">Home</Link></li>

            <li 
              className={`dropdown ${activeDropdown === 'staking' ? 'open' : ''}`}
              onMouseEnter={() => handleMouseEnter('staking')}
              onMouseLeave={handleMouseLeave}
            >
              <span className="dropdown-toggle">
                Staking
              </span>
              <ul className={`dropdown-menu ${activeDropdown === 'staking' ? 'show' : ''}`}>
                <li>
                  <a href="https://nodes.waveswaps.com/staking"  rel="noopener noreferrer">Stake WAVE</a>
                </li>
                <li>
                  <a href="https://nodes.waveswaps.com/staking-gbl" rel="noopener noreferrer">Stake GBL</a>
                </li>
              </ul>
            </li>

            <li 
              className={`dropdown ${activeDropdown === 'nodes' ? 'open' : ''}`}
              onMouseEnter={() => handleMouseEnter('nodes')}
              onMouseLeave={handleMouseLeave}
            >
              <span className="dropdown-toggle">
                Nodes
              </span>
              <ul className={`dropdown-menu ${activeDropdown === 'nodes' ? 'show' : ''}`}>
                <li>
                  <a href="https://nodes.waveswaps.com/store"  rel="noopener noreferrer">Market</a>
                </li>
                <li>
                  <a href="https://nodes.waveswaps.com/craft"  rel="noopener noreferrer">Crafting</a>
                </li>
                <li>
                  <a href="https://nodes.waveswaps.com/node-info"  rel="noopener noreferrer">About</a>
                </li>
              </ul>
            </li>

            <li 
              className={`dropdown ${activeDropdown === 'swaps' ? 'open' : ''}`}
              onMouseEnter={() => handleMouseEnter('swaps')}
              onClick={() => handleMouseEnter('swaps')}
              onMouseLeave={handleMouseLeave}
            >
              <span className="dropdown-toggle">
                Swaps
              </span>
              <ul className={`dropdown-menu ${activeDropdown === 'swaps' ? 'show' : ''}`}>
                <li>
                  <a href="https://swaps.waveswaps.com" rel="noopener noreferrer">GBL Swapping</a>
                </li>
              </ul>
            </li>

            <li 
              className={`dropdown ${activeDropdown === 'demo' ? 'open' : ''}`}
              onMouseEnter={() => handleMouseEnter('demo')}
              onMouseLeave={handleMouseLeave}
            >
              <span className="dropdown-toggle">
                Demo
              </span>
              <ul className={`dropdown-menu ${activeDropdown === 'demo' ? 'show' : ''}`}>
                <li>
                  <a href="https://testnodes.waveswaps.com" rel="noopener noreferrer">Liquidity Nodes</a>
                </li>
                <li>
                  <a href="https://faucet.waveswaps.com/" rel="noopener noreferrer">Token Faucet</a>
                </li>
              </ul>
            </li>

            <li 
              className={`dropdown ${activeDropdown === 'info' ? 'open' : ''}`}
              onMouseEnter={() => handleMouseEnter('info')}
              onMouseLeave={handleMouseLeave}
            >
              <span className="dropdown-toggle">
                Info
              </span>
              <ul className={`dropdown-menu ${activeDropdown === 'info' ? 'show' : ''}`}>
                <li>
                  <a href="https://nodes.waveswaps.com/tokens" rel="noopener noreferrer">Tokens</a>
                </li>
                <li>
                  <a href="https://nodes.waveswaps.com/node-info" rel="noopener noreferrer">About Nodes</a>
                </li>
                <li>
                  <a href="#FAQ" rel="noopener noreferrer">FAQs</a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
