import React from 'react';
import MatrixEffect from './MatrixEffect'; // Import MatrixEffect component

function HeroSection() {
  return (
    <section className="hero">
      {/* Adjust speed to control how fast the characters fall */}
      <MatrixEffect className="matrix-effect" quantity={10000} speed={0.05} />
      <div className="hero-content">
        <h1 class="hero-h1">Recycle, Revitalize, Revamp</h1>
        <p class="hero-p">Unlock your crypto's liquidity and connect with a thriving open-source DeFi community</p>
        <a href="https://docs.waveswaps.com/" className="hero-button">Read the Docs</a>
      </div>
    </section>
  );
}

export default HeroSection;
