import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import CardSection from './components/CardSection';
import RoadmapSection from './components/RoadmapSection';
import Footer from './components/Footer';
import FAQSection from './components/FAQSection';
import FlipNotification from './components/FlipNotification'; 
import About from './components/About'; 
import Supplies from './components/Supplies'; 
import './App.css'; 

function App() {
  return (
    <Router>
      <div className="App">
        <FlipNotification /> 
        <Header />
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <HeroSection />
                <CardSection />
                <RoadmapSection />
                <FAQSection />
                <Footer />
              </>
            } 
          />
          <Route path="/about" element={
            <>
                <About />
                <Footer /> 
              </>
            } 
          />
          <Route path="/supplies" element={
              <>
                <Supplies />
                <Footer /> 
              </>
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
