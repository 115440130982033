import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Settings from '../Settings';  // Ensure the path to SETTINGS is correct

{/* 
  const Gbl = '/assets/tokens/gbl.svg'; 
const Code = '/assets/tokens/code.svg'; 
const Meta = '/assets/tokens/meta.svg'; 
const Pipl = '/assets/tokens/pipl.svg'; 
const Geton = '/assets/tokens/geton.svg'; 
const Grosh = '/assets/tokens/grosh.svg'; 
const Pron = '/assets/tokens/pron.svg'; 
const Los = '/assets/tokens/los.svg'; 
const Carb = '/assets/tokens/carb.svg'; 
const Cbdx = '/assets/tokens/cbdx.svg'; 
const Apod = '/assets/tokens/apod.svg'; 
const Gmaar = '/assets/tokens/gmaar.svg';  */}

const Supplies = () => {
  const [tokenData, setTokenData] = useState({});
  const [loading, setLoading] = useState(true);

  // Utility to get the API URL for different networks
  const getApiUrlForNetwork = (network, tokenAddress, walletAddress, apiKey) => {
    switch (network) {
      case 'Polygon Mainnet':
        return `https://api.polygonscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      case 'BNB Smart Chain':
        return `https://api.bscscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      case 'Ethereum Mainnet':
        return `https://api.etherscan.io/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      default:
        console.error('Unsupported network:', network);
        return null;
    }
  };

  // Function to fetch balances for each wallet (Custody, Recycle Pool, etc.)
  const fetchBalance = async (asset, walletKey) => {
    const { tokenAddress, tokenDecimals, apiKey, network } = asset;
    const walletAddress = asset[walletKey];  // Dynamically get the correct wallet (Custody, Recycle Pool, etc.)

    // Skip fetching if token address is "0x" or wallet address is invalid
    if (!tokenAddress || tokenAddress === '0x' || !walletAddress || walletAddress === '0x') {
      return { balanceAmount: 0, formattedBalanceAmount: '0' };
    }

    const apiUrl = getApiUrlForNetwork(network, tokenAddress, walletAddress, apiKey);

    if (!apiUrl) return { balanceAmount: 0, formattedBalanceAmount: '0' };

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.status !== '1' || !data.result) {
        console.error(`Error fetching ${walletKey} amount:`, data.message || 'Invalid data');
        return { balanceAmount: 0, formattedBalanceAmount: '0' };
      }

      const balanceAmount = data.result / Math.pow(10, tokenDecimals);
      const formattedBalanceAmount = Math.floor(balanceAmount).toLocaleString();

      return { balanceAmount, formattedBalanceAmount };
    } catch (error) {
      console.error(`Error fetching ${walletKey} amount for`, walletKey, error);
      return { balanceAmount: 0, formattedBalanceAmount: '0' };
    }
  };

  // Fetch all data for tokens
  const fetchAllData = async () => {
    setLoading(true);
    const results = {};

    for (const [tokenName, tokenSettings] of Object.entries(Settings.Tokens || {})) {
      results[tokenName] = {};

      // Ethereum network balances
      if (tokenSettings.networks.ethereum.tokenAddress !== '0x') {
        const ethereumTotalSupply = parseFloat(tokenSettings.networks.ethereum.totalSupply.replace(/,/g, ''));
        results[tokenName]['Ethereum Total'] = tokenSettings.networks.ethereum.totalSupply;

        const ethereumCustody = await fetchBalance(tokenSettings.networks.ethereum, 'custodyWallet');
        results[tokenName]['Ethereum Custody'] = ethereumCustody.formattedBalanceAmount;

        const ethereumRecyclePool = await fetchBalance(tokenSettings.networks.ethereum, 'recyclePoolWallet');
        results[tokenName]['Ethereum Recycle Pool'] = ethereumRecyclePool.formattedBalanceAmount;

        const ethereumLiquidityPool = await fetchBalance(tokenSettings.networks.ethereum, 'liquidityPoolWallet');
        results[tokenName]['Ethereum Liquidity Pool'] = ethereumLiquidityPool.formattedBalanceAmount;

        const ethereumEcosystem = await fetchBalance(tokenSettings.networks.ethereum, 'ecosystemWallet');
        results[tokenName]['Ethereum Ecosystem'] = ethereumEcosystem.formattedBalanceAmount;

        // Calculate circulating supply for Ethereum
        const ethereumCirculatingSupply =
          ethereumTotalSupply -
          ethereumCustody.balanceAmount -
          ethereumRecyclePool.balanceAmount -
          ethereumLiquidityPool.balanceAmount -
          ethereumEcosystem.balanceAmount;

        results[tokenName]['Ethereum Circulating'] = Math.floor(ethereumCirculatingSupply).toLocaleString();
      }

      // BNB Smart Chain balances
      if (tokenSettings.networks.bsc.tokenAddress !== '0x') {
        const bscTotalSupply = parseFloat(tokenSettings.networks.bsc.totalSupply.replace(/,/g, ''));
        results[tokenName]['Smartchain Total'] = tokenSettings.networks.bsc.totalSupply;

        const bscCustody = await fetchBalance(tokenSettings.networks.bsc, 'custodyWallet');
        results[tokenName]['Smartchain Custody'] = bscCustody.formattedBalanceAmount;

        const bscRecyclePool = await fetchBalance(tokenSettings.networks.bsc, 'recyclePoolWallet');
        results[tokenName]['Smartchain Recycle Pool'] = bscRecyclePool.formattedBalanceAmount;

        const bscCirculatingSupply =
          bscTotalSupply -
          bscCustody.balanceAmount -
          bscRecyclePool.balanceAmount;

        results[tokenName]['Smartchain Circulating'] = Math.floor(bscCirculatingSupply).toLocaleString();
      }

      // Polygon network balances
      if (tokenSettings.networks.polygon.tokenAddress !== '0x') {
        const polygonTotalSupply = parseFloat(tokenSettings.networks.polygon.totalSupply.replace(/,/g, ''));
        results[tokenName]['Polygon Total'] = tokenSettings.networks.polygon.totalSupply;

        const polygonCustody = await fetchBalance(tokenSettings.networks.polygon, 'custodyWallet');
        results[tokenName]['Polygon Custody'] = polygonCustody.formattedBalanceAmount;

        const polygonRecyclePool = await fetchBalance(tokenSettings.networks.polygon, 'recyclePoolWallet');
        results[tokenName]['Polygon Recycle Pool'] = polygonRecyclePool.formattedBalanceAmount;

        const polygonCirculatingSupply =
          polygonTotalSupply -
          polygonCustody.balanceAmount -
          polygonRecyclePool.balanceAmount;

        results[tokenName]['Polygon Circulating'] = Math.floor(polygonCirculatingSupply).toLocaleString();
      }
    }

    setTokenData(results);
    setLoading(false);
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  // Helper function to render network and token logos
  const renderNetworkTokenLogos = (network, tokenName) => {
    const tokenSettings = Settings.Tokens[tokenName]?.networks[network];

    if (!tokenSettings || tokenSettings.tokenAddress === '0x') {
      return null; // Handle cases where the token is not deployed on this network
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <a href={tokenSettings.networkUrl} target="_blank" rel="noopener noreferrer">
          <img
            src={tokenSettings.networkLogo}
            alt="Network Logo"
            style={{ width: '18px', height: 'auto', marginRight: '20px' }}
          />
        </a>
        <a href={tokenSettings.tokenUrl} target="_blank" rel="noopener noreferrer">
          <img
            src={tokenSettings.tokenLogo}
            alt="Token Logo"
            style={{ width: '18px', height: 'auto' }}
          />
        </a>
      </div>
    );
  };

  return (
    <div className="supplies-container mt-3">
      <h2 className='pageTitle supplies-header'>Multichain Token Supplies</h2>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <button 
          onClick={fetchAllData} 
          style={{ padding: '10px 20px', fontSize: '14px', cursor: 'pointer', marginRight: '10px' }}
          className="refresh-button"
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Refresh Data'}
        </button>
      </div>
      <div className="table-responsive mb-3">
        <Table className="w-100 table-bordered-custom" style={{ borderCollapse: 'collapse', tableLayout: 'fixed' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid #dee2e6' }}>
              <th style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px', width: '150px' }}>Category</th>
              {Object.keys(Settings.Tokens || {}).map((tokenName, idx) => (
                <th style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px', width: '110px' }} key={idx}>
                  {Settings.Tokens[tokenName].tokenName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Ethereum row with logos */}
            <tr>
              <td style={{ textAlign: 'left', fontWeight: 'bold', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '14px', color: '#555' }}>
                Ethereum Mainnet
              </td>
              {Object.keys(Settings.Tokens || {}).map((tokenName, idx) => (
                <td key={idx} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {renderNetworkTokenLogos('ethereum', tokenName)}
                </td>
              ))}
            </tr>
            {/* Ethereum Total, Custody, Recycle Pool, Liquidity Pool and Ecosystem rows */}
            {['Ethereum Total', 'Ethereum Liquidity Pool', 'Ethereum Ecosystem', 'Ethereum Custody', 'Ethereum Recycle Pool'].map((constant, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'normal', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555', paddingRight: '10px' }}>
                  {constant.replace('Ethereum ', '')}
                </td>
                {Object.keys(Settings.Tokens || {}).map((tokenName, idx) => (
                  <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555', paddingRight: '10px' }} key={idx}>
                    {loading ? 'Loading...' : tokenData[tokenName] && tokenData[tokenName][constant] ? tokenData[tokenName][constant] : 'N/A'}
                  </td>
                ))}
              </tr>
            ))}
            {/* Ethereum Circulating Supply row (centered and bold) */}
            <tr>
              <td style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555', paddingRight: '10px' }}>
                Circulating
              </td>
              {Object.keys(Settings.Tokens || {}).map((tokenName, idx) => (
                <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555', paddingRight: '10px' }} key={idx}>
                  {loading ? 'Loading...' : tokenData[tokenName] && tokenData[tokenName]['Ethereum Circulating'] ? tokenData[tokenName]['Ethereum Circulating'] : 'N/A'}
                </td>
              ))}
            </tr>
            {/* BNB Smart Chain row with logos */}
            <tr>
              <td style={{ textAlign: 'left', fontWeight: 'bold', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '14px', color: '#555' }}>
                BNB Smart Chain
              </td>
              {Object.keys(Settings.Tokens || {}).map((tokenName, idx) => (
                <td key={idx} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {renderNetworkTokenLogos('bsc', tokenName)}
                </td>
              ))}
            </tr>
            {/* BNB Total, Custody, Recycle Pool, Circulating rows */}
            {['Smartchain Total', 'Smartchain Custody', 'Smartchain Recycle Pool'].map((constant, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'normal', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555', paddingRight: '10px' }}>
                  {constant.replace('Smartchain ', '')}
                </td>
                {Object.keys(Settings.Tokens || {}).map((tokenName, idx) => (
                  <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555', paddingRight: '10px' }} key={idx}>
                    {loading ? 'Loading...' : tokenData[tokenName] && tokenData[tokenName][constant] ? tokenData[tokenName][constant] : 'N/A'}
                  </td>
                ))}
              </tr>
            ))}
            {/* Smartchain Circulating Supply row (centered and bold) */}
            <tr>
              <td style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555', paddingRight: '10px' }}>
                Circulating
              </td>
              {Object.keys(Settings.Tokens || {}).map((tokenName, idx) => (
                <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555', paddingRight: '10px' }} key={idx}>
                  {loading ? 'Loading...' : tokenData[tokenName] && tokenData[tokenName]['Smartchain Circulating'] ? tokenData[tokenName]['Smartchain Circulating'] : 'N/A'}
                </td>
              ))}
            </tr>
            {/* Polygon Mainnet row with logos */}
            <tr>
              <td style={{ textAlign: 'left', fontWeight: 'bold', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '14px', color: '#555' }}>
                Polygon Mainnet
              </td>
              {Object.keys(Settings.Tokens || {}).map((tokenName, idx) => (
                <td key={idx} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {renderNetworkTokenLogos('polygon', tokenName)}
                </td>
              ))}
            </tr>
            {/* Polygon Total, Custody, Recycle Pool rows */}
            {['Polygon Total', 'Polygon Custody', 'Polygon Recycle Pool'].map((constant, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'normal', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555', paddingRight: '10px' }}>
                  {constant.replace('Polygon ', '')}
                </td>
                {Object.keys(Settings.Tokens || {}).map((tokenName, idx) => (
                  <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555', paddingRight: '10px' }} key={idx}>
                    {loading ? 'Loading...' : tokenData[tokenName] && tokenData[tokenName][constant] ? tokenData[tokenName][constant] : 'N/A'}
                  </td>
                ))}
              </tr>
            ))}
            {/* Polygon Circulating Supply row (centered and bold) */}
            <tr>
              <td style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555', paddingRight: '10px' }}>
                Circulating
              </td>
              {Object.keys(Settings.Tokens || {}).map((tokenName, idx) => (
                <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555', paddingRight: '10px' }} key={idx}>
                  {loading ? 'Loading...' : tokenData[tokenName] && tokenData[tokenName]['Polygon Circulating'] ? tokenData[tokenName]['Polygon Circulating'] : 'N/A'}
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Supplies;