import React from 'react';
import { Row, Col } from 'react-bootstrap';


const nodesImg = '/assets/images/nodes.svg'; 
const botsImg = '/assets/images/bots.svg'; 

const CardSection = () => {
  return (
    <section className="card-section py-5">
      <h2 className="text-center pageTitle py-5 mb-2">Liquidity Platforms</h2> {/* Margin below header */}
      <Row className="justify-content-center">
        <Col md={6} lg={4} className="mb-4 mb-4 d-flex"> {/* Add flex for vertical centering */}
          <div className="card mx-auto card-one"> {/* Specific class for background */}
          <img src={nodesImg} alt="Wave Nodes" className="card-image" />
            <p className="text-center">WAVE Liquidity Nodes</p>
            <button className="btn card-button" onClick={() => window.location.href='https://nodes.waveswaps.com/'}>
              Go to Platform
            </button>
          </div>
        </Col>
        <Col md={6} lg={4} className="mb-4 d-flex"> {/* Card 2 */}
          <div className="card mx-auto card-two">
          <img src={botsImg} alt="GBL Bots" className="card-image" />
            <p className="text-center">GBL Swapping Robots</p>
            <button className="btn card-button" onClick={() => window.location.href='https://swaps.waveswaps.com/'}>
              Go to Platform
            </button>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center mt-4"> {/* Center align the second row */}
        <Col md={6} lg={4} className="mb-4 mt-4 d-flex"> {/* Add flex for vertical centering */}
          <div className="card mx-auto card-three">
            <img src={nodesImg} alt="Wave Nodes" className="card-image" />
            <p className="text-center-three">T E S T I N G<span className="ampersand">&</span>T R A I N I N G</p>
            <button className="btn card-three-button" onClick={() => window.location.href='https://testnodes.waveswaps.com/'}>
              Go to Platform
            </button>
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default CardSection;
